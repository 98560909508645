@import "./Variables.scss";

.eventContainer {
    width: 100%;

    .slider {
        max-height: 250px;
        margin-bottom: 15px;
    }

    .slider {
        .slider-frame {
            max-height: 250px;

            .slider-list {
                max-height: 250px;

                .slider-slide {
                    // height: 250px !important;
                    position: relative;

                    img {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:focus-visible {
                        outline: unset;
                        
                    }
                }
            }
        }

        .slider-control-centerleft {
            left: -2.1875rem !important;
        }

        .slider-control-centerright {
            right: -2.1875rem !important;
        }
    }

    .eventImage {
        width: 100%;
        margin-bottom: 15px;
        height: 180px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 8px;

        // img {
        //     object-fit: cover;
        //     width: 100%;
        //     height: 250px;
        //     border-radius: 8px;
        // }
        .upcomingLabel {
            background-color: #444;
            color: #fff;
            text-transform: uppercase;
            padding: 9px 10px;
            border-radius: 4px;
            position: absolute;
            margin-top: 10px;
            margin-left: 10px;
            font-size: 10px;
            font-weight: bold;
        }
    }

    .eventImageRel {
        // background-image: none !important;
        height: auto;

        img {
            width: 100%;
            display: block;
            opacity: 0;
        }
    }

    .eventTitle {
        width: 70%;
        float: left;
        text-align: left;
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .eventBookBtn {
        width: 30%;
        float: left;
        text-align: right;

        button {
            float: right;
        }
    }

    .eventDate {
        width: 100%;
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        display: flex;
    }

    .eventStartingPrice {
        width: 100%;
        text-align: left;
        font-size: 14px;
        font-weight: bold;
    }
}

.block-heading {
    padding-bottom: 11px;
}

.padding-b-10 {
    padding-bottom: 10px;
}

.padding-t-10 {
    padding-top: 10px;
}

.eventDetails {
    margin-top: 5px;
    text-align: left;
    width: 100%;
    color: #333;
    font-size: 12px;

    ul,
    ol {
        padding-left: 20px;
        margin: 0px;

        li {
            font-family: inherit;
            font-size: 12px;
            position: relative;
        }
    }
}

hr {
    margin: 25px 0px !important;
}

.pageTitle {
    margin: 25px 0px !important;
    font-size: 20px !important;
    color: #444;
    font-weight: bold !important;
}

.viewEvent {
    background-color: #fff;
    &.eventShowDetails{
        min-height: calc(100vh - 60px);
        display: block !important;
    }
    // padding-bottom: 40px;
    .copyright {
        margin-bottom: 15px;
    }
    .eventViewLogoContainer{
        position: absolute;
        display: block;
        width: 80px;
        margin-top: -32px;
        right: 15px;
        .logo{
            border-radius: 7px;
            z-index: 1;
            position: relative;
        }
    }

    .eventPadding {
        padding: 0px 50px 10px;
        @media only screen and (max-width: 767px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .eventDaysList {
        margin-top: 5px;
    }

    .eventContainer {
        margin-top: 25px;

        .eventTitle {
            width: 100%;
            float: none;
        }
    }

    .allergiesForm {
        h5 {
            margin-bottom: 0px !important;
        }
    }

    .availableTimeSection {
        margin-bottom: 0px !important;
    }

    h5 {
        margin: 0 0 5px 0px !important;
    }

    .carousel-numbers {
        margin-bottom: 10px;
    }

    .allergiesForm {
        margin-bottom: 30px;
    }

    // .eventDetails {
    //     background-color: #f4f5f7;
    //     padding: 15px 8.33%;
    //     padding-bottom: 30px;
    //     align-items: left;
    //     text-align: left;
    // }
    // .buttonFix {
    //     .MuiButton-label {
    //         display: block;
    //     }
    // }
    .calculatedTotal {
        float: right;
        font-weight: bold;
        margin-right: 75px;
    }
}

.packages {
    padding-bottom: 10px;
}

.package {
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    text-align: left;
    padding: 10px !important;
    cursor: pointer;
    // min-height: 80px;
    margin-top: 15px !important;

    &.selected{
        @media only screen and (min-width: 800px) {
            width: 75% !important;
        }
    }

    .packageItem {
        display: flex;
        align-items: center;
    }

    .packageName {
        font-size: 14px;
        font-weight: 600;
        opacity: 0.7;

        .packageMaxGuests{
            font-size: 13px;
            opacity: 1;
        }
    }

    .packageDetails {
        font-size: 12px;
        font-weight: 400;
        opacity: 0.7;
    }

    .packagePrice {
        font-weight: 600;
        text-align: right;
        font-size: 12px;

        .pricePer {
            font-weight: 400;
        }
    }

    &:last-child {
        margin-bottom: 0px;
    }

    .packageDuration {
        font-size: 12px;
        font-weight: 700;
        color: #2a3f54;
        opacity: 1;
        padding-top: 5px;

        svg {
            vertical-align: middle;
            display: inline-block;
            margin-top: -2px;
            width: 14px;
            height: 14px;
            padding-right: 3px;
        }
    }
}

.packageField {
    max-width: 0px;
    margin-left: 0px;
    padding: 0px !important;
    display: none;
    .MuiTextField-root{
        width: 50%;
    }

    &.selected {
        max-width: calc(25% - 7px);
        margin-left: 7px;
        padding: 10px 0px !important;
        display: flex;
        align-items: center;
    }
}

.eventCarouselContainer {
    width: 100%;
    color: #444;

    &.event {
        padding: 10px 5px;
    }
    .eventCarouselTitle{
        text-align: center;
    }

    .eventCarouselImage {
        background-color: rgba(46, 97, 147, 0.2);
        width: 130px;
        height: 80px;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        background-size: contain;
        border-radius: 5px;

        @media only screen and (max-width: 767px) {
            width: 105px;
        }
        img {
            object-fit: cover;
            width: 100%;
            border-radius: 8px;
            height: 70px;
        }
    }

    .eventCarouselDetails {
        font-size: 13px;
        width: 130px;
        text-align: center;
        margin-top: 6px;

        @media only screen and (max-width: 767px) {
            width: 105px;
        }

        .eventName {
            font-weight: bold;
        }

        .eventTime {
            font-size: 14px;
        }
    }
}

.carouselRenderButtons {
    font-size: 35px !important;
    cursor: pointer;
    background-color: #fff;
}

.eventListContainer {
    background-color: #fff;
    padding-bottom: 25px;
}

.eventDaysList {
    text-align: left;
    margin-top: 10px;
    font-size: 13px;

    .eventListDay {
        margin-bottom: 3px;

        .eventDay {
            width: 220px;
            float: left;
        }

        a {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

@media all and (max-width: 768px) {
    .packageContainer {
        flex-direction: column;
    }

    .packageField,
    .package {
        float: none;
    }

    .packageField {
        margin-top: 5px;
    }

    .packageField.selected {
        max-width: 100%;
        margin-left: 0;
    }

    .eventCarouselContainer {
        .eventCarouselDetails {
            .eventName {
                font-weight: bold;
                font-size: 13px;
                word-wrap: break-word;
            }

            .eventDate {
                font-size: 12px;
            }

            .eventTime {
                font-size: 12px;
            }
        }
    }
}

.totalAmount {
    position: absolute;
    right: 50px;
    font-weight: 600;
    font-size: 18px;
}

@media only screen and (max-width: 599px) {
    .totalAmount {
        font-size: 14px;
    }
}

.numberBox .slider-control-centerleft {
    left: -2.1875rem !important;
}

.numberBox .slider-control-centerright {
    right: -2.1875rem !important;
}

.margin-b-0 {
    margin-bottom: 0px !important;
 }

 .slider-slide.slide-visible:focus-visible {
	outline: none !important;
}